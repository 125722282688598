import config from "@/config.js";
import axios from "axios";

export default class BackendClient {
  constructor(store) {
    // Class-local axios instance that is automatically populated with
    // relevant config, e.g. authorization headers.
    this.axios = axios.create();

    this.axios.interceptors.request.use(async (config) => {
      config.headers.common["Authorization"] = await store.dispatch("cognito/fetchJwtToken");
      return config;
    });

    this.axios.interceptors.response.use(
      (response) => {
        return response;
      },

      // Catch network errors
      async (err) => {
        store.commit("setSnackbar", {
          type: "error",
          msg: `Something went wrong: ${err.message}`,
          timeout: 10000,
        });
      }
    );
  }


  async searchVirksomhed(query) {
    // TODO: we probably just want an endpoint that does this for us
    // instead of doing this hacky shite
    let url = `${config.BackendUrl}"public/virksomhed/search"`;
    if (isNaN(query)) {
      url += `?navn=${query}`;
    } else {
      url += `?cvr_nummer=${query}`;
    }

    let result = await this.axios.get(url);
    if (result.status !== 200) {
      console.log("failed to find virksomheder", result);
      return [];
    }

    console.log(result);
    return result.data;
  }

  // API Keys
  async createApiKey(name) {
    const url = `${config.BackendUrl}/private/apikey`;

    const response = await this.axios.post(url, { name: name });
    return response.data.key;
  }

  async listApiKeys() {
    const url = `${config.BackendUrl}/private/apikeys`;

    return (await this.axios.get(url)).data.api_keys;
  }

  async deleteApiKey(id) {
    const url = `${config.BackendUrl}/private/apikey`;

    await this.axios({
      method: "DELETE",
      url: url,
      data: { id: id },
    });
  }

  async createCheckoutSession(priceId) {
    const url = `${config.BackendUrl}/private/stripe/checkoutsession`

    const result = await this.axios({
      method: "POST",
      url: url,
      data: { 'price_id': priceId },
    })
    return result.data.checkout_session_link;
  }

  async createCustomerPortalSession() {
    const url = `${config.BackendUrl}/private/stripe/customerportal`

    const result = await this.axios({
      method: "POST",
      url: url,
    })
    return result.data.customer_portal_link;
  }

  async listActiveProductAccessPeriods() {
    const url = `${config.BackendUrl}/private/productaccessperiods/active`

    const result = await this.axios({
      method: "GET",
      url: url,
    })
    return result.data;
  }

  async listRequestCounts() {
    const url = `${config.BackendUrl}/private/requestcounts`

    const result = await this.axios({
      method: "GET",
      url: url,
    })
    return result.data.month_requests;
  }
}
